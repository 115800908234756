<template>
	<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
		<a-form layout="vertical" :form="form" @submit="handleSubmit">
			<div class="row">
				<div class="col-md-6">
					<a-form-item :label="$t('suppliersModule.edit.emergencyContacts.fullName')">
						<a-input v-decorator="[
							'full_name',
							{
								rules: requiredFileds
							},
						]" autocomplete="off" />
					</a-form-item>
				</div>
				<div class="col-md-6">
					<a-form-item :label="$t('suppliersModule.edit.emergencyContacts.phone')">
						<a-input v-decorator="[
							'phone_number',
							{
								rules: requiredFileds
							},
						]" autocomplete="off" />
					</a-form-item>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6 text-left" v-if="actualContactRecord.id">
					<a-button class="btn btn-danger" icon="close" @click="onCancel">{{ $t('general.cancel') }}</a-button>
				</div>
				<div :class="actualContactRecord.id ? 'col-md-6' : 'col-md-12'" class="text-right">
					<a-button icon="save" class="btn btn-success" htmlType="submit">{{ $t('general.save') }}</a-button>
				</div>
			</div>
		</a-form>
	</a-spin>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'

export default {
	name: 'emergencyContactComponent',
	computed: {
		...mapGetters('suppliers', ['spinnerLoader', 'spinnerLoaderLabel', 'actualRecord', 'actualContactRecord']),
		isNewRecord() {
			return !utilities.objectValidate(this.actualContactRecord, 'id', false)
		},
		requiredFileds() {
			let rules = [{
				required: true,
				message: this.$t('general.requiredField'),
			}]
			return rules
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
		}
	},
	methods: {
		resetForm() {
			this.form.resetFields()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					//
					let payload = {
						...values,
						section: 'suppliers/emergency-contact',
						supplier_id: this.actualRecord.general.id,
					}
					if (this.isNewRecord) {
						await this.$store.dispatch('suppliers/CREATE', payload)
					} else {
						payload.id = this.actualContactRecord.id
						await this.$store.dispatch('suppliers/UPDATE', payload)
					}
					this.onCancel()
				}
			})
		},
		onCancel() {
			this.$store.commit('suppliers/SET_STATE', {
				actualContactRecord: {},
			})
			this.resetForm()
		},
	},
	watch: {
		actualContactRecord: {
			deep: true,
			handler(newValue) {
				if (utilities.objectValidate(newValue, 'id', false)) {
					this.form.setFieldsValue({
						full_name: utilities.objectValidate(newValue, 'full_name', ''),
						phone_number: utilities.objectValidate(newValue, 'phone_number', ''),
					})
				}
			},
		},
	},
}
</script>
