var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"tip":_vm.spinnerLoaderLabel,"size":"small","spinning":_vm.spinnerLoader}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":_vm.$t('suppliersModule.edit.emergencyContacts.fullName')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'full_name',
						{
							rules: _vm.requiredFileds
						},
					]),expression:"[\n\t\t\t\t\t\t'full_name',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: requiredFileds\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('a-form-item',{attrs:{"label":_vm.$t('suppliersModule.edit.emergencyContacts.phone')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'phone_number',
						{
							rules: _vm.requiredFileds
						},
					]),expression:"[\n\t\t\t\t\t\t'phone_number',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\trules: requiredFileds\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"autocomplete":"off"}})],1)],1)]),_c('div',{staticClass:"row"},[(_vm.actualContactRecord.id)?_c('div',{staticClass:"col-md-6 text-left"},[_c('a-button',{staticClass:"btn btn-danger",attrs:{"icon":"close"},on:{"click":_vm.onCancel}},[_vm._v(_vm._s(_vm.$t('general.cancel')))])],1):_vm._e(),_c('div',{staticClass:"text-right",class:_vm.actualContactRecord.id ? 'col-md-6' : 'col-md-12'},[_c('a-button',{staticClass:"btn btn-success",attrs:{"icon":"save","htmlType":"submit"}},[_vm._v(_vm._s(_vm.$t('general.save')))])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }