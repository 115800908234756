<template>
	<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
		<div class="row">
			<div class="col-md-6">
				<bankInformation />
			</div>
			<div class="col-md-6">
				<table class="table table-bordered table-condensed table-striped">
					<thead>
						<th width="40%" class="text-center">
							{{ $t('suppliersModule.edit.bankInfo.bankInstution') }}
							<br />
							<small>Tipo de cuenta</small>
						</th>
						<th width="40%" class="text-center">
							{{ $t('suppliersModule.edit.bankInfo.beneficiary') }}
							<br />
							<small>{{ $t('suppliersModule.edit.bankInfo.accountNumber') }} / {{ actualRecord.general.nationality == 'foreign' ? 'Código SWIFT' : 'Número CLABE' }}</small>
						</th>
						<th width="10%"></th>
					</thead>
					<tbody>
						<tr v-for="(element, index) in actualRecord.bank" :key="index">
							<td>
								{{ element.short_name }}
								<br />
								<small>Cuenta {{ element.principal ? ' principal' : 'secundaria' }}</small>
							</td>
							<td>
								{{ element.beneficiary }}
								<br />
								<small>{{ element.account_number | replaceIfLogicalFalse('-') }} / {{ element.clabe_number | replaceIfLogicalFalse('-') }}</small>
							</td>
							<td class="text-center">
								<a-button size="small" icon="minus-circle" @click="onToggleRecord(element.id)" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</a-spin>
</template>
<script>
//
import { mapGetters } from 'vuex'
import bankInformation from '@/components/bankInformation'
import Swal from 'sweetalert2'

export default {
	name: 'supplierBankComponent',
	components: {
		bankInformation,
	},
	computed: {
		...mapGetters('suppliers', ['spinnerLoader', 'spinnerLoaderLabel', 'actualRecord']),
		...mapGetters('auth', ['userData']),
	},
	methods: {
		onToggleRecord(id) {
			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: this.$t('general.deleteMessage.message'),
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('suppliers/TOGGLE', {
						id,
						section: `suppliers/bank-data`,
						supplier_id: this.userData.role == 'supplier' ? this.userData.id : this.$route.params.id,
					})
				}
			})
		},
	},
}
</script>
