<template>
	<a-form layout="vertical" :form="form" @submit="handleSubmit">
		<div class="row">
			<div class="col-md-5">
				<a-form-item :label="$t('suppliersModule.edit.bankInfo.accountType')">
					<a-select showSearch option-filter-prop="children" :filter-option="filterOption" v-decorator="[
						'principal',
						{
							rules: requiredFileds,
						},
					]" :disabled="!isEditable">
						<a-select-option value="true">Principal</a-select-option>
						<a-select-option value="false">Secundaria</a-select-option>
					</a-select>
				</a-form-item>
			</div>
			<div class="col-md-7">
				<a-form-item :label="$t('suppliersModule.edit.bankInfo.bankInstution')">
					<a-select showSearch option-filter-prop="children" :filter-option="filterOption" v-decorator="[
						'banking_institution_id',
						{
							rules: requiredFileds,
						},
					]" :disabled="!isEditable" @change="checkBankID">
						<a-select-option v-for="(element, index) in bankingInstitutionsList" :key="index" :value="element.id">{{ element.short_name }}</a-select-option>
					</a-select>
				</a-form-item>
			</div>
			<div class="col-md-12" v-if="verificationRequired">
				<a-form-item :label="$t('suppliersModule.edit.bankInfo.beneficiary')">
					<a-input v-decorator="[
						'beneficiary',
						{
							rules: requiredFileds,
						},
					]" autocomplete="off" :disabled="!isEditable" />
				</a-form-item>
			</div>
			<div class="col-md-6" v-if="verificationRequired">
				<a-form-item :label="$t('suppliersModule.edit.bankInfo.accountNumber')">
					<a-input v-decorator="[
						'account_number',
						{
							rules: requiredFileds,
						},
					]" v-mask="'####################'" autocomplete="off" :disabled="!isEditable" />
				</a-form-item>
			</div>
			<div class="col-md-6" v-if="verificationRequired">
				<a-form-item :label="actualRecord.general.nationality == 'foreign' ? 'Código SWIFT' : 'Número CLABE'">
					<a-input v-decorator="[
						'clabe_number',
						{
							rules: [...requiredFileds],
						},
					]" v-mask="'###########################'" autocomplete="off" :disabled="!isEditable" />
				</a-form-item>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6 text-left">
				<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">{{ $t('general.back') }}</a-button>
			</div>
			<div class="col-md-6 text-right">
				<a-button icon="save" class="btn btn-success" htmlType="submit" :disabled="!isEditable">{{ $t('general.save') }}</a-button>
			</div>
		</div>
	</a-form>
</template>

<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import { mask } from 'vue-the-mask'
import numeral from 'numeral'

export default {
	name: 'supplierBankComponent',
	directives: {
		mask,
	},
	computed: {
		...mapGetters('suppliers', ['spinnerLoader', 'spinnerLoaderLabel', 'actualRecord']),
		...mapGetters('bankingInstitutions', ['bankingInstitutionsList']),
		...mapGetters('auth', ['userData']),
		isNewRecord() {
			return !utilities.objectValidate(this.actualRecord, 'bank.id', false)
		},
		requiredFileds() {
			return [
				{
					required: true,
					message: this.$t('general.requiredField'),
				},
			]
		},
		isEditable() {
			return this.actualRecord.bank.length < 3
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			verificationRequired: false,
		}
	},
	async beforeMount() {
		if (!this.bankingInstitutionsList.length) {
			this.$store.dispatch('bankingInstitutions/GET')
		}
	},
	mounted() {
		if (utilities.objectValidate(this.actualRecord, 'bank.id', false)) {
			this.setLocalData(this.actualRecord)
		}
	},
	methods: {
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		setLocalData(newValue) {
			if (newValue) {
				this.form.setFieldsValue({
					banking_institution_id: utilities.objectValidate(newValue, 'bank.banking_institution_id', ''),
					beneficiary: utilities.objectValidate(newValue, 'bank.beneficiary', ''),
					account_number: utilities.objectValidate(newValue, 'bank.account_number', ''),
					clabe_number: utilities.objectValidate(newValue, 'bank.clabe_number', ''),
				})
			}
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					//
					let payload = {
						...values,
						section: 'suppliers/bank-data',
						supplier_id: this.userData.role == 'supplier' ? this.userData.id : this.$route.params.id,
					}
					if (this.isNewRecord) {
						await this.$store.dispatch('suppliers/CREATE', payload)
					} else {
						payload.id = this.actualRecord.bank.id
						await this.$store.dispatch('suppliers/UPDATE', payload)
					}
					this.form.resetFields()
				}
			})
		},
		onCancel() {
			if (this.userData.role == 'supplier') {
				this.$router.replace('/')
			} else {
				this.$router.replace('/suppliers')
			}
		},
		checkBankID(id) {
			this.verificationRequired = numeral(id).value() != 102
		},
	},
	watch: {
		actualRecord: {
			deep: true,
			handler(newValue) {
				if (utilities.objectValidate(newValue, 'bank.id', false)) {
					this.setLocalData(newValue)
				}
			},
		},
	},
}
</script>
