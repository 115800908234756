<template>
	<div>
		<div class="row">
			<div class="col-md-7">
				<emergencyContact />
			</div>
			<div class="col-md-5">
				<table class="table table-bordered table-condensed table-striped">
					<thead>
						<th class="text-center">{{ $t('suppliersModule.edit.emergencyContacts.tableHeaders.name') }}</th>
						<th class="text-center">{{ $t('suppliersModule.edit.emergencyContacts.tableHeaders.phone') }}</th>
						<th width="15%"></th>
					</thead>
					<tbody v-if="!hasContacts">
						<tr>
							<td colspan="3" class="text-center">
								<h3 class="text-muted">- {{ $t('suppliersModule.edit.emergencyContacts.noData') }} -</h3>
							</td>
						</tr>
					</tbody>
					<tbody v-else>
						<tr v-for="(element, index) in actualRecord.contacts" :key="index">
							<td>{{ element.full_name }}</td>
							<td>{{ element.phone_number }}</td>
							<td class="text-center">
								<a-button type="info" size="small" icon="edit" @click="onEditRecord(element.id)" />
								<a-button class="ml5" size="small" icon="minus-circle" @click="onToggleRecord(element.id)" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4 text-left">
				<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">{{ $t('general.back') }}</a-button>
			</div>
		</div>
	</div>
</template>
<script>
//
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import emergencyContact from '@/components/emergencyContact'

export default {
	name: 'supplierContactsComponent',
	components: {
		emergencyContact,
	},
	computed: {
		...mapGetters('suppliers', ['spinnerLoader', 'spinnerLoaderLabel', 'actualRecord']),
		hasContacts() {
			return !!this.actualRecord.contacts.length
		},
	},
	data() {
		return {}
	},
	methods: {
		onCancel() {
			this.$router.replace('/suppliers')
		},
		onToggleRecord(id) {
			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: this.$t('general.deleteMessage.message'),
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					let payload = {
						supplier_id: this.actualRecord.general.id,
						section: 'suppliers/emergency-contact',
						id,
					}
					this.$store.dispatch('suppliers/TOGGLE', payload)
				}
			})
		},
		onEditRecord(id) {
			this.$store.commit('suppliers/SET_STATE', {
				actualContactRecord: _.cloneDeep(this.actualRecord.contacts.find((e) => e.id == id)),
			})
		},
	},
}
</script>
